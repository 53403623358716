import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useModal } from '../context/ModalContext';

// Define types for form values
interface IFormInput {
  name: string;
  contactNo: string;
  gstNo: string;
  product: string;
}

export const ContactForm: React.FC = () => {
  const { modalData } = useModal()
  const { register, handleSubmit, formState: { errors }, reset } = useForm<IFormInput>({
    defaultValues: {
      product: modalData?.product
    }
  });
  const [formMessage, setFormMessage] = React.useState<string | null>(null);

  // Handler for form submission
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setFormMessage(`Form submitted successfully!`);
    console.log(data);
    reset(); // Reset the form after submission
  };

  return (
    <div className="container">
      <h2 className='fw-bold text-center mb-3'>Your Information </h2>
      {formMessage && <Alert variant="success">{formMessage}</Alert>}
      <Form onSubmit={handleSubmit(onSubmit)} className='col-lg-8 mx-auto'>
        <Form.Group controlId="formName" className='mb-3'>
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            {...register('name', { required: 'Name is required' })}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formContactNo" className='mb-3'>
          <Form.Label>Your Contact No</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your contact number"
            {...register('contactNo', {
              required: 'Contact number is required',
              pattern: {
                value: /^[0-9]{10}$/,
                message: 'Contact number must be 10 digits'
              }
            })}
            isInvalid={!!errors.contactNo}
          />
          <Form.Control.Feedback type="invalid">
            {errors.contactNo?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formGstNo" className='mb-3'>
          <Form.Label>Your GST No</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your GST number"
            {...register('gstNo', {
              required: 'GST number is required',
              pattern: {
                value: /^[0-9A-Z]{15}$/,
                message: 'GST number must be 15 characters'
              }
            })}
            isInvalid={!!errors.gstNo}
          />
          <Form.Control.Feedback type="invalid">
            {errors.gstNo?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formProduct">
          <Form.Label>Product</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter product name"
            {...register('product', { required: 'Product is required' })}
            isInvalid={!!errors.product}
          />
          <Form.Control.Feedback type="invalid">
            {errors.product?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <button type="submit" className="btn btn-primary mt-3 w-100">
          SUBMIT
        </button>
      </Form>
    </div>
  );
};

