import { Col, Container, Row } from 'react-bootstrap'

export const CustomerReview = () => {
    return (
        <div className="py-lg-5 my-lg-5">
            <div className='bg-light pt-lg-0 pt-5'>
                <Container>
                    <Row className='align-items-end'>
                        <Col md={8}>
                            <div className="row align-items-end">
                                <div className="col-md-6">
                                    <img src={require('../assets/review.png')} className='w-100 review-image' />
                                </div>
                                <div className="col-md-6 text-lg-start text-center my-lg-0 my-3">
                                    <h4 className="fw-bold">Customer reviews</h4>
                                    <p>Help is here. whenever and however you need it</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="text-lg-end text-center mb-lg-5 mb-3">
                                <h4 className="fw-bold">Productivity essentials</h4>
                                <p>Help is here, whenever and however you need it</p>
                                <button className="btn btn-dark fw-medium btn-sm">
                                    Talk to us
                                </button>
                            </div>
                            <img src={require('../assets/persons.png')} className='w-100 d-block' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
