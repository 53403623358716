import { FC, useState } from 'react'
import { Container } from 'react-bootstrap'
import { CategoryButtonGroup } from './CategoryButtonGroup'
import { ProductCardGroup } from './ProductCardGroup'

export const ProductsGrid: FC = () => {
    const [categoryName, setCategoryName] = useState('all')
    return (
        <Container className='py-lg-5 py-4'>
            <CategoryButtonGroup categoryName={categoryName} setCategoryName={setCategoryName} />
            <ProductCardGroup categoryName={categoryName} />
        </Container>
    )
}
