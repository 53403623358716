import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'

export const KeyPoints: FC = () => {
    return (
        <Row className='align-items-center m-0 border-top gap-3 border-bottom py-4'>
            <Col md={2}>
                <h3 className='fw-bold'>Applepoint</h3>
                Promise | Trust & Reliable
            </Col>
            <Col md={2}>
                <div className="bg-light-1 rounded p-3 d-flex gap-3 align-items-center h-100">
                    <img src={require('../assets/user.png')} />
                    <div>
                        Get expert service
                        <div>and support</div>
                    </div>
                </div>
            </Col>
            <Col md={2}>
                <div className="bg-light-2 rounded p-3 d-flex gap-3 align-items-center h-100">
                    <img src={require('../assets/upgrade.png')} />
                    <div>
                        Trade-in | Upgrade
                        <div>to the new</div>
                    </div>
                </div>
            </Col>
            <Col className='pe-md-0'>
                <div className="bg-dark text-white rounded rounded-md-start p-3 d-flex gap-3 align-items-center">
                    <img src={require('../assets/van.png')} />
                    <div>
                        <div className='fw-bold fs-5'>Fast, no-contact delivery</div>
                        <small>Get your desired Apple product at the comfort of your home.</small>
                    </div>
                </div>
            </Col>

        </Row>
    )
}
