import React from 'react';

export const PaymentForm: React.FC = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <h3 className='fs-5 fw-bold text-center mb-3 text-uppercase'>Scan QR FoR payment </h3>
          <img src={require('../assets/qrcode.jpg')} className='w-100' />
        </div>
        <div className="col-md-7">
          <h3 className='fs-5 fw-bold text-center mb-3 text-uppercase'>Bank details</h3>
          <table className='table'>
            <tbody>
              <tr>
                <th> Account Holder</th>
                <td>:</td>
                <td> APPLE POINT</td>
              </tr>
              <tr>
                <th> Account Number</th>
                <td>:</td>
                <td> 50200059198505</td>
              </tr>
              <tr>
                <th> IFSC</th>
                <td>:</td>
                <td> HDFC0000004</td>
              </tr>
              <tr>
                <th> Branch</th>
                <td>:</td>
                <td> ITC CENTRE ANNA SALAI</td>
              </tr>
              <tr>
                <th> Account Type</th>
                <td>:</td>
                <td> CURRENT</td>
              </tr>
              <tr>
                <th> UPI Id</th>
                <td>:</td>
                <td> 9884363671@hdfcbank</td>
              </tr>
            </tbody>
          </table>
          <center>
            <img src={require('../assets/upi-logos.png')} width={200} className='mx-auto mt-3' />
          </center>
        </div>
        <div className="col-12">
          <div className="lead text-center mt-3">
            <div> Refund policy: Payment initiated will be refunded within</div>
            <div><b><u>15 days</u></b> as per policy norms.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

