import React, { useState } from 'react';
import {
    Container,
    Navbar,
    Offcanvas
} from 'react-bootstrap';
import { MdEmail } from "react-icons/md";
import { PiPhoneCallFill } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import { CategoryNavBar } from './CategoryNavBar';
import { SecondaryNavbar } from './SecondaryNavbar';

const Header: React.FC = () => {
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };
    const onClose = () => setShowSidebar(false)
    return (
        <>
            <Navbar expand="lg" className="bg-light shadow flex-column w-100 p-0" sticky="top">
                <Container className='py-2'>
                    {/* Row 1 */}
                    <Navbar.Brand as={NavLink} to={'/'}>
                        <img
                            src={require('../assets/logo.png')} // Path to your logo
                            alt="Logo"
                            height="50"
                        />
                    </Navbar.Brand>
                    <div className="d-none d-lg-flex gap-3 align-items-center">
                        <div>
                            <a className="cta-link-icon fw-bold fs-6 mb-1" href='tel:919884363671'>
                                +91-98843 63671
                                <div className="icon-badge dark">
                                    <PiPhoneCallFill />
                                </div>
                            </a>
                            <a className="cta-link-icon small" href='mailto:info@applepoint.com'>
                                info@applepoint.com
                                <div className="icon-badge dark">
                                    <MdEmail />
                                </div>
                            </a>
                        </div>
                        <div className='vr'></div>
                        <img src={require('../assets/auth-box.png')} height={50} />
                    </div>
                    {/* Toggle Button */}
                    <Navbar.Toggle
                        aria-controls="offcanvasNavbar"
                        onClick={toggleSidebar}
                        className="d-lg-none"
                    />
                </Container>
                <div className='d-none d-lg-block w-100'>
                    <SecondaryNavbar theme='dark' />
                </div>
                <div className='d-none d-lg-block w-100'>
                    <CategoryNavBar />
                </div>
            </Navbar>
            <Offcanvas
                show={showSidebar}
                onHide={toggleSidebar}
                placement="end"
                className="d-lg-none"
            >
                <Offcanvas.Header closeButton className='shadow-sm'>
                    <Offcanvas.Title>
                        <img
                            src={require('../assets/logo.png')} // Path to your logo
                            alt="Logo"
                            height="50"
                        />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="bg-white shadow border p-2 pb-3 mb-3 rounded overflow-hidden">
                        <center>
                            <div className='py-3'>
                                <a className="d-flex align-items-center justify-content-center mx-auto fw-bold fs-6 mb-1" href='tel:919884363671'>
                                    <div className="icon-badge light">
                                        <PiPhoneCallFill />
                                    </div>
                                    +91-98843 63671
                                </a>
                                <a className="d-flex align-items-center justify-content-center mx-auto small" href='mailto:info@applepoint.in'>
                                    <div className="icon-badge light">
                                        <MdEmail />
                                    </div>
                                    info@applepoint.in
                                </a>
                            </div>
                            <img src={require('../assets/auth-box.png')} height={50} />
                        </center>
                    </div>
                    <div className="bg-white shadow border mb-3 pb-2 rounded overflow-hidden">
                        <SecondaryNavbar onClose={onClose} theme="white" />
                    </div>
                    <div className="bg-white shadow border mb-3 rounded overflow-hidden">
                        <CategoryNavBar onClose={onClose} />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Header;
