import { FC } from "react";
import { Container } from "react-bootstrap";
import { ProductCardGroup } from "./ProductCardGroup";

export const FeaturesCategory: FC = () => {
    return (
        <div className="py-lg-5 py-4">
            <Container>
                <h4 className="fw-bold">Accessories</h4>
                <p>Extravagant Experiences for Lifetime</p>
                <ProductCardGroup categoryName={'66af8836a452328cf7e8f7f3'} />
            </Container>
        </div>
    )
}
