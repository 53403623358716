import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { useModal } from '../context/ModalContext';
import { getAllProducts } from '../services';

export const ProductCardGroup = ({ categoryName }: { categoryName: String }) => {
    const { openModal, openContactForm } = useModal()
    const { data, error, } = useQuery({
        queryKey: ['products', categoryName],
        queryFn: () => getAllProducts(categoryName),
        placeholderData: keepPreviousData,
    });

    const handleClick = (product: any) => {
        openContactForm({ product })
    }

    if (error) return <div>Error: {error.message}</div>;

    return (
        <Row id='products'>
            {data?.data?.map((item: any) => (
                <Col md={6} sm={6} lg={4} xl={3} key={item._id} className='py-3'>
                    <Card style={{ minHeight: 370 }}>
                        <div className='badge bg-light text-dark fw-medium'>{item.tag}</div>
                        <img src={item.image.url} className='product-image' />
                        <CardBody className='text-center'>
                            <div className="mb-3">
                                <div className='fs-6 fw-medium'>{item.name}</div>
                                <div className='small'>{item.description}</div>
                                <div className='small'>{item.specifications}</div>
                            </div>
                            <div className="d-inline-flex align-items-center gap-1 btn-sm justify-content-center">
                                <button className="btn btn-light border fw-bold btn-sm" onClick={openModal}>
                                    Buy now
                                </button>
                                <button className="btn btn-dark fw-medium btn-sm" onClick={() => handleClick(item.name)}>
                                    Talk to us
                                </button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}
