export function formatPriceINR(price: number): string {
    // Convert the price to a fixed-point notation with two decimal places
    let priceString: string = Number(price).toFixed(2);

    // Split price into whole and fractional parts
    let [whole, fraction]: string[] = priceString.split('.');

    // Extract the last three digits
    let lastThreeDigits: string = whole.slice(-3);
    let otherDigits: string = whole.slice(0, -3);

    if (otherDigits !== '') {
        lastThreeDigits = ',' + lastThreeDigits;
    }

    // Insert commas for every two digits before the last three digits
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

    // Join the formatted parts together
    whole = otherDigits + lastThreeDigits;

    // Join the whole part with the fractional part
    return `₹${whole}`;
}