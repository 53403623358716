import {
    Col,
    Container,
    Nav,
    Navbar,
    Row
} from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { useModal } from '../context/ModalContext';

export const SecondaryNavbar = ({ theme, onClose }: { theme: "dark" | "light" | "white", onClose?: () => void }) => {
    const { openContactForm } = useModal()
    return (
        <Navbar bg={theme} data-bs-theme={theme} className='w-100'>
            <Container>
                <Row className="w-100 mx-0 d-flex align-items-center">
                    <Col lg={10} className='px-0'>
                        <Nav className="mr-auto d-block d-lg-inline-flex text-center text-lg-start">
                            <Nav.Link as={NavLink} className='me-lg-3' onClick={onClose} to="/">Home</Nav.Link>
                            <Nav.Link as={NavLink} className='me-lg-3' onClick={onClose} to="/products">Products</Nav.Link>
                            <Nav.Link className='me-lg-3 mx-auto' as={'button'} onClick={openContactForm}>Contact us</Nav.Link>
                            <Nav.Link className='me-lg-3 mx-auto' as={'button'} onClick={openContactForm} >Support</Nav.Link>
                        </Nav>
                    </Col>
                    <Col lg={2} className='text-lg-end text-center p-0'>
                        <hr className='d-lg-none d-block' />
                        <div className="d-inline-flex align-items-center gap-2 btn-sm">
                            <a href='https://api.whatsapp.com/send?phone=919884363671' target='_blank' className="btn btn-light btn-sm">
                                <FaWhatsapp size={20} />
                            </a>
                            <button onClick={openContactForm} className="btn btn-light fw-bold btn-sm">
                                Talk to us
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Navbar>)
}
