import { FC } from 'react'
import { Container } from 'react-bootstrap'
import { KeyPoints } from '../components/KeyPoints'
import { ProductsGrid } from '../components/ProductsGrid'
import { useModal } from '../context/ModalContext'

export const Products: FC = () => {
    const { openContactForm } = useModal()

    return (
        <>
            <Container className='hero-section'>
                <div>Apple Lineups</div>
                <h1 className='h3 fw-bold'>Friendly. Local. Experts</h1>
                <div>We have exactly what you need</div>
                <img src={require('../assets/product-banner.png')} className='banner-image' />
                <div>
                    <button onClick={openContactForm} className="btn btn-dark fw-medium btn-sm mb-3">
                        Talk to us
                    </button>
                    <div> All cards accepted | <b>emi options</b> for all banks and cards</div>
                </div>
            </Container>
            <KeyPoints />
            <ProductsGrid />
        </>
    )
}
