import React from 'react';
import { Modal } from 'react-bootstrap';
import { useModal } from '../context/ModalContext';
import { ContactForm } from './ContactForm';
import { PaymentForm } from './PaymentForm';

const GlobalModal: React.FC = () => {
  const { showModal, modalType, closeModal, openPaymentModal } = useModal();
  if (modalType === 'VerifyForm') {
    return (
      <Modal show={showModal} size='sm' onHide={closeModal} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton className='text-center justify-content-center'>
          <Modal.Title className='fw-bold fs-5'>
            Availability <span className="text-primary">Check</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <div> Check the device/product availability </div>
          <div className='mb-3'>before initiating the payment.  </div>
          <div className="d-inline-flex align-items-center gap-1 btn-sm justify-content-center">
            <a href='https://api.whatsapp.com/send?phone=919884363671' target='_blank' className="btn btn-light border fw-medium btn-sm">
              Connect to Check
            </a>
            <button className="btn btn-primary fw-medium btn-sm" onClick={openPaymentModal}>
              Proceed to payment
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <Modal show={showModal} size='lg' onHide={closeModal} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton className='border-0 text-center justify-content-center'>
        <Modal.Title>
          <img src={require('../assets/logo.png')} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalType === 'ContactForm' ? <ContactForm /> : null}
        {modalType === 'PaymentForm' ? <PaymentForm /> : null}
      </Modal.Body>
    </Modal>
  );
};

export default GlobalModal;
