import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useModal } from '../context/ModalContext';
import { getFeatureProducts } from '../services';
import { formatPriceINR } from '../utils';

export const FeaturesProducts: FC = () => {
    const { openContactForm, openModal } = useModal()

    const { data, error, isLoading } = useQuery({
        queryKey: ['FeatureProducts'],
        queryFn: getFeatureProducts
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="bg-light py-lg-5 py-4">
            <Container className='py-lg-5'>
                <div className="grid-temp">
                    {
                        data?.data?.map((item: any, key: number) => (
                            <div className={`grid-item${key + 1} grid-item shadow rounded position-relative`}>
                                <div className="row align-items-center justify-content-center">
                                    <div className={key == 0 ? "col-10" : "col-lg-6 text-center"}>
                                        <img src={item.image.url} className={key == 0 ? "w-100" : "feature-image"} />
                                    </div>
                                    <div className={`${key == 0 ? 'col-lg-12' : 'col-lg-6'} text-center p-3`}>
                                        <div className='badge bg-light text-dark fw-medium'>{item.tag}</div>
                                        <div className='fs-5 fw-bold'>{item.name}</div>
                                        <div className='small'>{item.description}</div>
                                        <div className='small'>{item.specifications}</div>
                                        <div className='fs-6 fw-bold'>starts from {formatPriceINR(item.price)}</div>
                                    </div>
                                    <div className={`col-lg-12 text-center ${key !== 0 ? 'pt-4' : ''}`}>
                                        <div className="d-inline-flex align-items-center gap-1 btn-sm justify-content-center">
                                            <button onClick={openModal} className="btn btn-light border fw-bold btn-sm">
                                                Buy now
                                            </button>
                                            <button onClick={() => openContactForm({ product: item.name })} className="btn btn-dark fw-medium btn-sm">
                                                Talk to us
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </div>
    )
}
