import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { CustomerReview } from '../components/CustomerReview';
import { FeaturesCategory } from '../components/FeaturesCategory';
import { FeaturesProducts } from '../components/FeaturesProducts';
import { KeyPoints } from '../components/KeyPoints';
import { useModal } from '../context/ModalContext';

const Home: FC = () => {
    const { openContactForm, openModal } = useModal()

    return (
        <>
            <Container className='hero-section'>
                <div>Apple Lineups</div>
                <h1 className='h3 fw-bold'>Mind-blowing | Head-turning</h1>
                <div>Most powerful machines with</div>
                <div>ultra-fast chips. and USB-C</div>
                <img src={require('../assets/home-banner.png')} className='banner-image' />
                <div>
                    <div className="d-inline-flex align-items-center gap-2 btn-sm mb-3">
                        <button onClick={openModal} className="btn btn-light border fw-bold btn-sm">
                            Buy now
                        </button>
                        <button onClick={openContactForm} className="btn btn-dark fw-bold btn-sm">
                            Talk to us
                        </button>
                    </div>
                    <div> All cards accepted | <b>Emi options</b> for all banks and cards</div>
                </div>
            </Container>
            <KeyPoints />
            <FeaturesProducts />
            <FeaturesCategory />
            <CustomerReview />
        </>
    );
};

export default Home;
