import React, { createContext, ReactNode, useContext, useState } from 'react';


interface ModalContextType {
  showModal: boolean;
  modalType: any;
  modalData: any;
  openPaymentModal: any;
  closeModal: () => void;
  openModal: () => void;
  openContactForm: (item?: any) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('ContactForm');
  const [modalData, setModalData] = useState<any>({ product: '' });

  const openModal = () => {
    setModalType('VerifyForm');
    setShowModal(true);
  };

  const openPaymentModal = () => {
    setModalType('PaymentForm');
    setShowModal(true);
  };

  const openContactForm = (item: any) => {
    setModalData(item)
    setModalType('ContactForm');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, modalType, openPaymentModal, modalData, openModal, openContactForm, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
