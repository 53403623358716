import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ModalProvider } from '../context/ModalContext';
import { Footer } from './Footer';
import GlobalModal from './GlobalModal';
import Header from './Header';

const Layout: React.FC = () => {
    const { search, pathname } = useLocation()

    useEffect(() => {
        if (pathname !== '/products') {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }, [search]);

    return (
        <ModalProvider>
            <Header />
            <Outlet />
            <Footer />
            <GlobalModal />
        </ModalProvider>
    );
};

export default Layout;
