import { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { useModal } from '../context/ModalContext'

export const Footer: FC = () => {
    const { openContactForm } = useModal()

    return (
        <footer>
            <Container>
                <hr className='my-5' />
                <div className="d-flex gap-2 align-items-center">
                    <img src={require('../assets/apLogo.png')} />
                    <div className='fw-bold'>Apple point</div>
                    <div className="vr"></div>
                    <div className='fw-normal'>Mac </div>
                    <div className="vr"></div>
                    <div className='fw-normal'>MacBook Pro</div>
                </div>
                <Row className='mt-4'>
                    <Col lg={6}>
                        <Row>
                            <Col sm={4} className='text-center text-lg-start pb-3 pb-0'>
                                <div className="fw-medium">Products</div>
                                <div>Mac</div>
                                <div>iPad</div>
                                <div>iPhone</div>
                                <div>Watch</div>
                                <div>Vision</div>
                                <div>Airpods</div>
                                <div>TV & Home</div>
                                <div>Air tag</div>
                            </Col>
                            <Col sm={4} className='text-center text-lg-start pb-3 pb-0'>
                                <div className="fw-medium">Quick links</div>
                                <div>About us</div>
                                <div>Contact us</div>
                                <div>Find our store</div>
                                <div>Accessories</div>
                                <div>Service & Support</div>
                            </Col>
                            <Col sm={4} className='text-center text-lg-start pb-3 pb-0'>
                                <div className="fw-medium">Policies</div>
                                <div>Privacy</div>
                                <div>Return policy</div>
                                <div>Shipping policy</div>
                                <div>Terms & conditions</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className='text-center text-lg-end'>
                        <div className="mb-3">
                            <div className='fw-bold'><u>Apple Authorized Reseller</u></div>
                            <div>Applepoint Store</div>
                            <div>1st floor, south wing, Splencer plaza</div>
                            <div>Thousand lights, Anna salai, chennai.</div>
                        </div>
                        <div className='d-inline-sm-flex'>
                            <img src={require('../assets/auth-box.png')} height={50} />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3 align-items-end pt-lg-4'>
                    <Col lg={6} className='text-center text-lg-start'>
                        <div className="d-inline-flex gap-3 align-items-center mb-2">
                            <div className='fw-bold'>Sales & Services</div>
                            <div className="vr"></div>
                            <div className='fw-normal'>Connect with us</div>
                        </div> <br />
                        <div className="form-group">
                            <input type="text" placeholder='Type your message, will connect you' />
                            <button className='btn btn-dark btn-sm' onClick={openContactForm}>Talk to us</button>
                        </div>
                    </Col>
                    <Col lg={6} className='text-center text-lg-end mt-3 mt-lg-0'>
                        <div className="d-inline-flex gap-3">
                            <a href=""><FaFacebook size={24} /></a>
                            <a href=""><FaTwitter size={24} /></a>
                            <a href=""><FaInstagram size={24} /></a>
                            <a href=""><FaYoutube size={24} /></a>
                        </div> <br />
                        <img src={require('../assets/payment-icons.png')} height={40} />
                    </Col>
                </Row>
            </Container>
            <div className="bg-dark text-white py-4 mt-5">
                <Container>
                    <Row>
                        <Col sm={6} className='text-center text-lg-start'>
                            <div>Copyright @2024 <a href="">ejnarstudios</a> | All rights reserved by Applepoint </div>
                        </Col>
                        <Col sm={6} className='text-center text-lg-end'>
                            <div>Chennai, India</div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}
