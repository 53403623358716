import { useQuery } from '@tanstack/react-query';
import { Container, Placeholder } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getAllCategories } from '../services';

export const CategoryNavBar = ({ onClose }: { onClose?: () => void }) => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['categories'],
        queryFn: getAllCategories
    });

    if (error) return <div>Error: {error.message}</div>;
    return (
        <div className="bg-light w-100 py-3">
            <Container>
                <div className='w-100 d-flex flex-wrap justify-content-lg-between justify-content-center gap-3'>
                    {
                        isLoading ?
                            [...Array(8)].map(() => (
                                <div className='text-center d-block my-3 my-lg-0' key={Math.random()}>
                                    <Placeholder animation="glow">
                                        <Placeholder style={{ height: 60, width: 100, borderRadius: 10 }} />
                                    </Placeholder>
                                </div>
                            ))
                            :
                            data.data.map((item: any) => (
                                <NavLink key={item._id} className='text-center d-block my-3 my-lg-0' onClick={onClose} to={`/products?category=${item.slug}`}>
                                    <img src={item.image.url} className='category-icon' />
                                    <div className='category-name'>{item.name}</div>
                                </NavLink>
                            ))
                    }
                </div>
            </Container>
        </div>
    )
}
