
import axios from 'axios';
export const baseUrl = `https://ej-projects.vercel.app/api/web`;

export const getAllCategories = async () => {
    const response = await axios.post(baseUrl, {
        moduleName: "applepoint_product_categories",
        sorting: [
            { id: 'order_by', desc: 1 }
        ]
    })

    return response.data;
};

export const getAllProducts = async (categoryName: any) => {
    let options: any = {
        moduleName: "applepoint_products",
        limit: 25,
    }

    if (categoryName !== 'all') {
        options.filters = [
            {
                value: categoryName,
                id: "category_id"
            }
        ]
    }
    const response = await axios.post(baseUrl, options)

    return response.data;
};

export const getFeatureProducts = async () => {
    let options: any = {
        moduleName: "applepoint_products",
        limit: 7,
        sorting: [
            { id: 'feature_order', desc: 1 }
        ],
        filters: [
            {
                value: 'true',
                id: "is_feature"
            }
        ]
    }
    const response = await axios.post(baseUrl, options)
    return response.data;
};