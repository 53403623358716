import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllCategories } from '../services';

export const CategoryButtonGroup = ({ setCategoryName, categoryName }: any) => {
    const ref: any = useRef(null)
    const { search } = useLocation()
    const navigate = useNavigate()
    const categoryPathName = search.split('=')[1]
    const { data, error, isFetched } = useQuery({
        queryKey: ['categories'],
        queryFn: getAllCategories
    });
    useEffect(() => {
        if (ref?.current && search !== '') {
            const width = window.innerWidth < 768 ? 100 : 250
            console.log(ref.current.offsetTop - width)
            window.scrollTo({
                top: ref.current.offsetTop - width,
                behavior: "smooth"
            })
        }
    }, [search])

    if (error) return <div>Error: {error.message}</div>;

    const CategoryButton = ({ _id, name, slug }: any) => {
        const handleClick = () => {
            setCategoryName(_id)
            if (_id == 'all') {
                navigate(`/products`)
            } else {
                navigate(`/products?category=${slug}`)
            }
        }
        const isActive = categoryName === _id
        return (
            <button className={`btn border fw-medium ${isActive ? 'btn-dark ' : 'btn-light border-dark'}`} onClick={handleClick}>
                <span className='category-name'>{name}</span>
            </button>
        )
    }

    const CategoryButtons = () => {
        useEffect(() => {
            const filtered = data.data.filter((item: any) => item.slug === categoryPathName)
            if (filtered.length) {
                setCategoryName(filtered[0]._id)
            }

        }, [categoryPathName])
        return (
            <div className='w-100 d-flex flex-wrap gap-3' ref={ref}>
                <CategoryButton _id='all' name='all' />
                {data.data.map((item: any) => (
                    <CategoryButton key={item._id} {...item} />
                ))}
            </div>
        )
    }

    if (isFetched) return <CategoryButtons />
    return <div>Loading...</div>;
}


